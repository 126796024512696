import Routes from 'Routes'
import Typography from 'rv-components-onze/src/Typography'
import SectionReadOurLetter from '../SectionReadOurLetter'
import EncourageEmployee from 'components/EncourageEmployee'
import { isMobile } from 'utils/getDevice'
import { sliderImages } from './constants'

import enhancer from './hooks'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, A11y } from 'swiper'
import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Pagination, A11y])

const { yourCompany } = Routes

const ManagementHero = ({ onClickSidebar }) => {
  const Components = {
    readOurLetter: SectionReadOurLetter,
  }

  const bulletList = [
    {
      description: 'Fundos recomendados a partir do perfil de investidor de cada profissional',
    },
    {
      description: 'Múltiplos fundos disponíveis para a construção do futuro do seu time',
    },
    {
      description: 'Experiência acessível, sem necessidade de conhecimento técnico',
    },
    {
      description: 'Fundos de todas as classes: renda fixa, multimercado, ações',
    },
    {
      description:
        '<b>E mais:</b> suporte consultivo com planejadores certificados',
    },
  ]


  return (
    <section className="management-hero">
      <div className="container">
        <div className="management-hero__slider">
          <Typography
            as="h3"
            variant="headline5"
            className="management-hero__slider--title"
            weight="bold">
            Com a Onze, seus colaboradores têm acesso 
            a uma carteira diversificada com uma das maiores gestoras do país
          </Typography>
          <EncourageEmployee
            imageSrc="/img-onze-gestora-fundos04.png"
            title="Uma experiência completa e intuitiva"
            buttonLabel="Fale com a Onze"
            buttonAction={onClickSidebar}
            bulletList={bulletList}
          />
          <div className="management-hero__slider--staw">
            <Swiper
              slidesPerView={isMobile() ? 1 : 5}
              pagination={{
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 4,
              }}>
              {sliderImages.map((item, idx) => (
                <SwiperSlide>
                  <img
                    className="management-hero__slider--image"
                    src={`/images/${item.src}`}
                    alt={item.alt}
                    key={`${item.alt}-${idx}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default enhancer(ManagementHero)
